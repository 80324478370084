import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | BoldSolutions
			</title>
			<meta name={"description"} content={"Там, де кожен матч відчувається як домашня гра"} />
			<meta property={"og:title"} content={"Головна | BoldSolutions"} />
			<meta property={"og:description"} content={"Там, де кожен матч відчувається як домашня гра"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11:58:37.084Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="image" />
			<Override slot="image1" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-1.jpg?v=2024-09-23T11:58:37.079Z) center/cover"
			padding="160px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			min-height="750px"
		>
			<Box margin="0" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 40px">
							Bold Solutions
						</Text>
						<Text as="p" font="--lead" margin="20px 0">
							Ваше улюблене поле для товариських матчів, командних тренувань або просто для гри з друзями. Розташований у самому серці вашого міста, ми надаємо простір, де пристрасть до футболу поєднується з духом громади.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 50px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Ласкаво просимо до BoldSolutions
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Наше єдине, ідеально доглянуте футбольне поле професійного рівня обладнане всім необхідним для ідеальної гри. Освітлене прожекторами для вечірньої гри та вкрите пишною, доглянутою травою, поле BoldSolutions пропонує преміальний ігровий досвід. Тут мова йде не просто про оренду поля, а про любов до гри.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Чому варто обрати BoldSolutions?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Відмінне розташування
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Легкодоступне, з достатньою кількістю місць для паркування.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Ексклюзивне бронювання
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Зарезервуйте все поле для вашої групи.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Якісне покриття
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Високоякісний газон для плавної гри.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Доступні ціни
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Конкурентоспроможні ціни без прихованих платежів.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11:58:37.084Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11%3A58%3A37.084Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-width="100%"
					lg-text-align="center"
				>
					Зв'язатися з нами
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Зв'яжіться з нами, щоб забронювати слот або дізнатися про наші послуги. Наша команда потурбується про те, щоб ваша гра пройшла без проблем.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Button
						display="inline-block"
						max-width="350px"
						align-self="center"
						type="link"
						min-width="250px"
						text-align="center"
						text-transform="uppercase"
						text-decoration-line="initial"
						href="/contacts"
						background="--color-primary"
					>
						написати
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});